import React, { useState } from "react"

export default function Recover() {
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [done, setDone] = useState(false)

    const handleSubhmit = evt => {
        evt.preventDefault()
        if (!email) return
        fetch("/api/recover", { method: "POST", body: JSON.stringify({ email: email }) })
            .then(r => {
                if (r.status === 404) {
                    setMessage("Account not found")
                }
                return r.json()
            })
            .then(data => {
                if (data.msg) setMessage(data.msg)
                if (data.done) setDone(true)
            })
    }

    return done ? (
        <div className="hero is-medium">
            <div className="hero-body">
                <div className="container">
                    <div className="columns">
                        <div className="column is-offset-4 is-4">
                            <h1 className="title">Account recovery</h1>
                            <p>Check e-mail : {email}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="hero is-medium">
            <div className="hero-body">
                <div className="container">
                    <div className="columns">
                        <div className="column is-offset-4 is-4">
                            <div className="title">Recover account</div>
                            {message && <div className="notification is-danger">{message}</div>}
                            <form onSubmit={handleSubhmit}>
                                <div className="field">
                                    <label className="label">E-mail</label>
                                    <div className="control">
                                        <input
                                            type="text"
                                            className="input"
                                            value={email}
                                            onChange={e => {
                                                setEmail(e.target.value)
                                            }}
                                            autoFocus
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="field">
                                    <div className="control">
                                        <input type="submit" value="Submit" className="button is-primary" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
