import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBackward, faForward, faPause, faPlay } from "@fortawesome/free-solid-svg-icons"
import { formatDuration } from "./utils"
import { authFetch, logout } from "./auth"
import black from "./black.png"

export const PlayerContext = React.createContext()

export function PlayerContextProvider({ children }) {
    const trackList = useRef(null)
    const trackIndex = useRef(null)
    const [playing, setPlaying] = useState(false)
    const [track, setTrack] = useState(null)
    const [currentTime, setCurrentTime] = useState(null)
    const audio = useRef()
    const [album, setAlbum] = useState(null)

    useEffect(() => {
        if ("mediaSession" in navigator) {
            navigator.mediaSession.setActionHandler("play", resume)
            navigator.mediaSession.setActionHandler("pause", pause)
            navigator.mediaSession.setActionHandler("previoustrack", prev)
            navigator.mediaSession.setActionHandler("nexttrack", next)
            navigator.mediaSession.setActionHandler("stop", clear)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setAlbumAndPlay = (album, index) => {
        setAlbum(album)
        trackList.current = album.tracks
        if (trackList.current.length === 0) return
        playIndex(index)
    }

    const playIndex = index => {
        if (!trackList.current) return
        trackIndex.current = index
        const track = trackList.current[trackIndex.current]
        setTrack(track)
        setPlaying(false)
        authFetch("/api/track/" + track.id)
            .then(r => {
                if (r.status === 422) {
                    logout()
                    return Promise.reject()
                }
                return r.json()
            })
            //.catch(error=>{console.log(error)})
            .then(data => {
                audio.current.src = data
                const playPromise = audio.current.play()
                if (playPromise !== undefined) {
                    playPromise
                        .then(_ => {
                            setPlaying(true)
                            if ("mediaSession" in navigator) {
                                navigator.mediaSession.metadata = new window.MediaMetadata({
                                    title: track.title,
                                    artist: track.artist,
                                    album: album.title,
                                    artwork: [{ src: album.cover ? album.cover : black, sizes: "any", type: "image/jpeg" }]
                                })
                            }
                        })
                        .catch(error => {})
                }
            })
    }

    const pause = () => {
        setPlaying(false)
        if (audio.current) audio.current.pause()
        if (navigator.mediaSession) navigator.mediaSession.playbackState = "paused"
    }

    const resume = async () => {
        await audio.current.play()
        setPlaying(true)
        if (navigator.mediaSession) navigator.mediaSession.playbackState = "playing"
    }

    const prev = () => {
        if (!trackList.current) return
        const index = trackIndex.current - 1
        if (index >= 0) playIndex(index)
    }

    const next = () => {
        if (!trackList.current) return
        const index = trackIndex.current + 1
        if (index < trackList.current.length) playIndex(index)
    }

    const clear = () => {
        pause()
        setAlbum(null)
        setTrack(null)
        trackList.current = null
        trackIndex.current = null
        if ("mediaSession" in navigator) {
            navigator.mediaSession.metadata = new window.MediaMetadata()
            navigator.mediaSession.playbackState = "none"
        }
    }

    const defaultContext = {
        setAlbumAndPlay,
        trackList,
        trackIndex,
        track,
        playing,
        pause,
        resume,
        clear
    }

    return (
        <PlayerContext.Provider value={defaultContext}>
            {children}
            {track && (
                <section className="section player">
                    <div className="container">
                        <audio ref={audio} onTimeUpdate={e => setCurrentTime(e.target.currentTime)} onEnded={next} />
                        <div className="level">
                            <div className="level-left">
                                <button className="button" onClick={prev}>
                                    <FontAwesomeIcon icon={faBackward} />
                                </button>
                                {playing ? (
                                    <button className="button" onClick={pause}>
                                        <FontAwesomeIcon icon={faPause} />
                                    </button>
                                ) : (
                                    <button className="button" onClick={resume}>
                                        <FontAwesomeIcon icon={faPlay} />
                                    </button>
                                )}
                                <button className="button" onClick={next}>
                                    <FontAwesomeIcon icon={faForward} />
                                </button>
                            </div>
                            <div className="level-item">
                                <div className="has-text-centered" style={{ width: "50%" }}>
                                    {track.artist} - {track.title}
                                    <br />
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <span className="is-size-7">{formatDuration(currentTime)}</span>
                                                </td>
                                                <td>
                                                    <input
                                                        className="slider is-fullwidth is-small is-circle"
                                                        step="1"
                                                        min="0"
                                                        max={track.duration}
                                                        value={currentTime || 0}
                                                        onChange={e => (audio.current.currentTime = e.target.value)}
                                                        type="range"
                                                    />
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <span className="is-size-7">{formatDuration(track.duration)}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="level-right">
                                {album && (
                                    <figure className="image is-50x50">
                                        <Link to={`/${album.slug}`}>
                                            <img src={album.cover ? album.cover : black} alt="" />
                                        </Link>
                                    </figure>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </PlayerContext.Provider>
    )
}
