import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import history from "../history"
import { AuthContext, login } from "../auth"

export default function Retrieve() {
    const [message, setMessage] = useState("")
    const authContext = useContext(AuthContext)
    const { code } = useParams()

    useEffect(() => {
        if (!code) return
        fetch("/api/retrieve/" + code)
            .then(r => r.json())
            .then(data => {
                if (data.msg) setMessage(data.msg)
                if (data.access_token) {
                    login({ access_token: data.access_token, refresh_token: data.refresh_token })
                    authContext.invalidateUserInfo()
                    history.push("/password")
                }
            })
    }, [authContext, code])

    return message ? (
        <div className="hero is-medium">
            <div className="hero-body">
                <div className="container">
                    <div className="columns">
                        <div className="column is-offset-4 is-4">
                            <div className="notification is-danger">{message}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null
}
