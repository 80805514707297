import React, { useState } from "react"
import { authFetch } from "../auth"

export default function Password() {
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const [error, setError] = useState(false)
    const [message, setMessage] = useState("")

    const handleSubhmit = evt => {
        evt.preventDefault()
        if (password1 !== password2) {
            setMessage("Passwords mismatch.")
            setError(true)
            return
        }
        if (password1.length < 5) {
            setMessage("Password too short.")
            setError(true)
            return
        }
        setMessage("")
        setError(false)
        authFetch("/api/password", { method: "POST", body: JSON.stringify({ password: password1 }) })
            .then(r => r.json())
            .then(data => {
                if (data.msg) setMessage(data.msg)
                setPassword1("")
                setPassword2("")
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className="hero is-medium">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4">
                            <div className="title">Password</div>
                            <form onSubmit={handleSubhmit}>
                                <div className="field">
                                    <label className="label">New password</label>
                                    <div className="field">
                                        <input
                                            type="password"
                                            className="input"
                                            value={password1}
                                            onChange={e => {
                                                setPassword1(e.target.value)
                                            }}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">Confirm</label>
                                    <div className="field">
                                        <input
                                            type="password"
                                            className="input"
                                            value={password2}
                                            onChange={e => {
                                                setPassword2(e.target.value)
                                            }}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="field">
                                    <div className="control">
                                        <input type="submit" value="Submit" className="button is-primary" />
                                    </div>
                                </div>
                            </form>
                            {message && (
                                <>
                                    <br />
                                    <div className={`notification ${error ? "is-danger" : "is-success"}`}>{message}</div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
