import React, { useState, useContext } from "react"
import { Link, useLocation } from "react-router-dom"
import { login, useAuth, logout, AuthContext } from "./index"
import history from "../history"

export default function Login() {
    const [credentials, setCredentials] = useState({
        username: "",
        password: ""
    })

    const authContext = useContext(AuthContext)
    const [logged] = useAuth()
    const [message, setMessage] = useState("")

    const location = useLocation()

    const onChange = evt => {
        setCredentials({ ...credentials, [evt.target.name]: evt.target.value })
    }

    const handleSubmit = evt => {
        evt.preventDefault()
        fetch("/api/login", {
            method: "POST",
            body: JSON.stringify(credentials)
        })
            .then(r => r.json())
            .then(data => {
                if (data.access_token) {
                    login(data)
                    authContext.invalidateUserInfo()
                    const params = new URLSearchParams(location.search)
                    const next = params.get("next")
                    history.push(next ? next : "/")
                } else {
                    setMessage("Please type in correct username/password")
                }
            })
    }

    const handleReauthenticate = evt => {
        evt.preventDefault()
        authContext.setUserInfo(null)
        logout()
    }

    return (
        <div className="hero is-medium">
            <div className="hero-body">
                <div className="columns is-centered">
                    <div className="column is-4">
                        <h2 className="title">Login</h2>
                        {message && <div className="notification is-danger">{message}</div>}
                        {!logged ? (
                            <>
                                <form onSubmit={handleSubmit}>
                                    <div className="field">
                                        <input
                                            name="username"
                                            className="input"
                                            type="text"
                                            placeholder="Username"
                                            onChange={onChange}
                                            value={credentials.username}
                                        />
                                    </div>
                                    <div className="field">
                                        <input
                                            name="password"
                                            className="input"
                                            type="password"
                                            placeholder="Password"
                                            onChange={onChange}
                                            value={credentials.password}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <br />
                                    <button type="submit" className="button is-primary">
                                        Login Now
                                    </button>
                                </form>
                                <br />
                                <Link to="/recover">Password lost ?</Link>
                            </>
                        ) : (
                            <button onClick={handleReauthenticate} type="submit" className="button">
                                Reauthenticate
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
