import React, { useEffect, useState } from "react"
import jwt_decode from "jwt-decode"
import { useAuth, localStorageKey } from "./"

export default function TokenInfo() {
    const [decodedA, setDecodedA] = useState(null)
    const [decodedR, setDecodedR] = useState(null)
    const [logged] = useAuth()

    useEffect(() => {
        const raw_data = window.localStorage.getItem(localStorageKey)
        if (!raw_data) return
        const data = JSON.parse(raw_data)
        const token = data.access_token
        if (token) {
            setDecodedA(JSON.stringify(jwt_decode(data.access_token)))
            setDecodedR(JSON.stringify(jwt_decode(data.refresh_token)))
        } else {
            setDecodedA(null)
            setDecodedR(null)
        }
    }, [])

    return (
        <>
            <h2 className="title">Token Info</h2>
            <tt>logged : {JSON.stringify(logged)}</tt>
            <br />
            <br />
            <tt>access token : {decodedA}</tt>
            <br />
            <br />
            <tt>refresh token : {decodedR}</tt>
        </>
    )
}
