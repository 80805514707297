import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { PlayerContext } from "./PlayerContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons"
import { formatDuration } from "./utils"
import { authFetch } from "./auth"
import Album from "./lib/Album"

export default function Playlist() {
    const [album, setAlbum] = useState(null)
    const playerContext = useContext(PlayerContext)
    const { artist, title } = useParams()

    useEffect(() => {
        const slug = artist + "/" + title
        authFetch("/api/album/" + slug)
            .then(r => {
                if (r.status !== 200) return Promise.reject(r)
                return r.json()
            })
            .then(data => {
                if (data) {
                    setAlbum(data)
                } else setAlbum(null)
            })
    }, [artist, title])

    const play = idx => {
        if (!album || !album.tracks.length) return
        playerContext.setAlbumAndPlay(album, idx)
    }

    return (
        <div className="columns is-multiline">
            {album && (
                <div className="album">
                    <Album {...album} />
                </div>
            )}
            <div className="tracklist">
                {album && album.tracks.length > 0 ? (
                    <table className="table is-fullwidth">
                        <tbody>
                            {album.tracks.map((item, idx) => (
                                <tr
                                    key={idx}
                                    style={{ cursor: "pointer" }}
                                    onClick={
                                        !playerContext.track || playerContext.track.id !== item.id ? e => play(idx) : undefined
                                    }>
                                    <td className="has-text-centered" width="1%">
                                        {playerContext.track && playerContext.track.id === item.id ? (
                                            playerContext.playing ? (
                                                <div onClick={playerContext.pause}>
                                                    <FontAwesomeIcon icon={faPause} size="xs" />
                                                </div>
                                            ) : (
                                                <div onClick={playerContext.resume}>
                                                    <FontAwesomeIcon icon={faPlay} size="xs" />
                                                </div>
                                            )
                                        ) : (
                                            <span className="is-size-7">{item.pri}</span>
                                        )}
                                    </td>
                                    <td>{item.title}</td>
                                    <td width="1%">
                                        <span className="is-size-7">{formatDuration(item.duration)}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <em>no track</em>
                )}
            </div>
        </div>
    )
}
