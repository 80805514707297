import React from "react"
import { Link, matchPath, Redirect, Route, Router, Switch, withRouter } from "react-router-dom"
import loadable from "@loadable/component"
import { PlayerContext, PlayerContextProvider } from "./PlayerContext"
import history from "./history"
import { useAuth, AuthContext, AuthContextProvider } from "./auth"
import Collection from "./Collection"
import Playlist from "./Playlist"
import Login from "./auth/Login"
import Logout from "./auth/Logout"
import TokenInfo from "./auth/TokenInfo"
import Password from "./user/Password"
import Recover from "./user/Recover"
import Retrieve from "./user/Retrieve"

const Dashboard = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard"))
const Accounts = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard/Accounts"))

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [logged] = useAuth()

    return (
        <Route
            {...rest}
            render={props => {
                if (logged) return <Component {...props} />
                else return <Redirect to={"/login?next=" + encodeURIComponent(props.location.pathname)} />
            }}
        />
    )
}

const Navigation = withRouter(({ location }) => {
    const m = matchPath(location.pathname, { path: "/retrieve/:code", exact: true })
    const noNav = m || ["/login", "/recover"].includes(location.pathname)
    return (
        !noNav && (
            <div className="container">
                <nav className="navbar" role="navigation" aria-label="main navigation">
                    <Link to="/" className="navbar-item">
                        Home
                    </Link>
                    <Link to="/token-info" className="navbar-item">
                        Token Info
                    </Link>
                    <label
                        role="button"
                        className="navbar-burger"
                        aria-label="menu"
                        aria-expanded="false"
                        htmlFor="nav-toggle-state">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </label>
                    <input type="checkbox" id="nav-toggle-state" />
                    <div className="navbar-menu">
                        <div className="navbar-end">
                            <AuthContext.Consumer>
                                {({ userInfo }) => {
                                    return userInfo ? (
                                        <div className="navbar-end">
                                            <div className="navbar-item">
                                                <em>{userInfo.username}</em>
                                            </div>
                                            {userInfo.roles.has("admin") && (
                                                <Link to="/dashboard" className="navbar-item">
                                                    Dashboard
                                                </Link>
                                            )}
                                            <Link to="/password" className="navbar-item">
                                                Password
                                            </Link>
                                            <Link to="/logout" className="navbar-item">
                                                Logout
                                            </Link>
                                        </div>
                                    ) : (
                                        <div className="navbar-end">
                                            <Link to="/login" className="navbar-item">
                                                Login
                                            </Link>
                                        </div>
                                    )
                                }}
                            </AuthContext.Consumer>
                        </div>
                    </div>
                </nav>
            </div>
        )
    )
})

export default function App() {
    return (
        <Router history={history}>
            <PlayerContextProvider>
                <AuthContextProvider>
                    <Navigation />
                    <PlayerContext.Consumer>
                        {() => (
                            <section className="section">
                                <div className="container">
                                    <Switch>
                                        <Route exact path="/login" component={Login} />
                                        <Route exact path="/logout" component={Logout} />
                                        <Route exact path="/token-info" component={TokenInfo} />
                                        <Route exact path="/recover" component={Recover} />
                                        <Route exact path="/retrieve/:code" component={Retrieve} />
                                        <PrivateRoute exact path="/" component={Collection} />
                                        <PrivateRoute exact path="/password" component={Password} />
                                        <PrivateRoute exact path="/dashboard" component={Dashboard} />
                                        <PrivateRoute exact path="/dashboard/accounts" component={Accounts} />
                                        <PrivateRoute exact path="/:artist/:title" component={Playlist} />
                                        <Route path="*" render={() => <h2 className="title">Not found</h2>} />
                                    </Switch>
                                </div>
                            </section>
                        )}
                    </PlayerContext.Consumer>
                    <br />
                    <br />
                    <br />
                    <br />
                </AuthContextProvider>
            </PlayerContextProvider>
        </Router>
    )
}
