import React from "react"
//import { pure } from "recompose"
import black from "../black.png"

//export default pure(props => {
export default function Album(props) {
    return (
        <article>
            <figure className="image is-296x296">
                <img src={props.cover ? props.cover : black} width="296" height="296" alt="" />
            </figure>
            <p className="has-text-weight-bold">{props.artist}</p>
            <p>{props.title}</p>
            <p className="is-size-7 is-italic">{props.year}</p>
        </article>
    )
}
