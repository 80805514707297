import React, { useEffect, useState } from "react"
import history from "./history"
import { authFetch } from "./auth"
import Album from "./lib/Album"

export default function Collection() {
    const [albums, setAlbums] = useState(null)

    useEffect(() => {
        authFetch("/api/albums")
            .then(r => r.json())
            .then(data => {
                if (data) setAlbums(data)
                else setAlbums(null)
            })
    }, [])

    const openAlbum = slug => {
        history.push(slug)
    }

    return albums ? (
        <div className="columns is-multiline">
            {albums.map((item, idx) => (
                <div key={idx} className="album" style={{ cursor: "pointer" }} onClick={e => openAlbum(item.slug)}>
                    <Album {...item} />
                </div>
            ))}
        </div>
    ) : null
}
